import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import LoginLayout from './LoginLayout';
import authService from "../../services/authService";

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    isRetailer: false,
    business: {
      business_name: '',
      business_address: '',
      tax_id: ''
    }
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('business.')) {
      const businessField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        business: {
          ...prev.business,
          [businessField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!agreeToTerms) {
      setError('Please agree to the Privacy Policy to continue');
      return;
    }

    try {
      await authService.signup(formData);
      window.location.href = '/login';
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred during registration');
    }
  };


  const handlePrivacyClick = (e) => {
    e.preventDefault();
    // Handle privacy policy navigation
  };

  return (
      <LoginLayout>
        <div className="login-main">
          {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
          )}

          <form className="theme-form" onSubmit={handleSubmit}>
            <h4>Create your account</h4>
            <p>Enter your personal details to create account</p>

            <div className="form-group">
              <label className="col-form-label pt-0">Your Name</label>
              <div className="row g-2">
                <div className="col-sm-6">
                  <input
                      className="form-control"
                      name="firstName"
                      type="text"
                      required
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="First name"
                  />
                </div>
                <div className="col-sm-6">
                  <input
                      className="form-control"
                      name="lastName"
                      type="text"
                      required
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Last name"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-form-label">Email Address</label>
              <input
                  className="form-control"
                  name="email"
                  type="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="test@gmail.com"
              />
            </div>

            <div className="form-group">
              <label className="col-form-label">Phone Number</label>
              <input
                  className="form-control"
                  name="phone"
                  type="tel"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="+1 (555) 555-5555"
              />
            </div>

            <div className="form-group">
              <label className="col-form-label">Password</label>
              <div className="form-input position-relative">
                <input
                    className="form-control"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="*********"
                />

                <div className="show-hide">
                  <span onClick={() => setShowPassword(!showPassword)}>
                    <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                  </span>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="checkbox">
                <input
                    id="isRetailer"
                    name="isRetailer"
                    type="checkbox"
                    checked={formData.isRetailer}
                    onChange={handleChange}
                />
                <label className="text-muted" htmlFor="isRetailer">
                  I am a retailer
                </label>
              </div>
            </div>

            {formData.isRetailer && (
                <div className="form-group border-top pt-3">
                  <h5 className="m-b-20">Business Information</h5>
                  <p>
                    <div className="form-group">
                      <label className="col-form-label">Business Name</label>
                      <input
                          className="form-control"
                          name="business.business_name"
                          type="text"
                          required={formData.isRetailer}
                          value={formData.business.business_name}
                          onChange={handleChange}
                          placeholder="Your Business Name"
                      />
                    </div>

                    <div className="form-group">
                      <label className="col-form-label">Business Address</label>
                      <input
                          className="form-control"
                          name="business.business_address"
                          type="text"
                          required={formData.isRetailer}
                          value={formData.business.business_address}
                          onChange={handleChange}
                          placeholder="Full business address"
                      />
                    </div>

                    <div className="form-group">
                      <label className="col-form-label">Tax ID (optional)</label>
                      <input
                          className="form-control"
                          name="business.tax_id"
                          type="text"
                          value={formData.business.tax_id || ''}
                          onChange={handleChange}
                          placeholder="Tax ID number"
                      />
                    </div>
                  </p>
                </div>
            )}

            <div className="form-group mb-0">
              <div className="checkbox p-0">
                <input
                    id="terms"
                    type="checkbox"
                    checked={agreeToTerms}
                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                />
                <label className="text-muted" htmlFor="terms">
                  Agree with
                  <a
                      href="#"
                      onClick={handlePrivacyClick}
                      className="ms-2"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>
              <button className="btn btn-primary btn-block w-100" type="submit">
                Create Account
              </button>
            </div>

            <hr />
            <p className="mt-4 mb-0">
              Already have an account?&nbsp;
              <Link to="/login">Sign in here</Link>
            </p>
          </form>
        </div>
      </LoginLayout>
  );
};

export default Register;
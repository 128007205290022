import React from 'react';

const Logo = () => {
    return (
        <div className="logo-wrapper">
            <a className="logo" href="/">
                <img className="img-fluid for-light" src="/assets/images/logo/logo.png" alt="logo"/>
                <img className="img-fluid for-dark" src="/assets/images/logo/logo_dark.png" alt="dark-logo"/>
            </a>
        </div>
    );
};

export default Logo;

import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import LoginLayout from './LoginLayout';
import authService from '../../services/authService';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });

    const token = searchParams.get('token');

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await authService.verifyResetToken(token);
                if (response.valid) {
                    setIsTokenValid(true);
                } else {
                    setError('Invalid or expired reset link');
                }
            } catch (err) {
                setError('Invalid or expired reset link');
            } finally {
                setIsLoading(false);
            }
        };

        if (token) {
            verifyToken();
        } else {
            setError('No reset token provided');
            setIsLoading(false);
        }
    }, [token]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            await authService.resetPassword({
                token,
                new_password: formData.password
            });
            navigate('/dashboard');
        } catch (err) {
            setError('Failed to reset password. Please try again.');
        }
    };

    if (isLoading) {
        return (
            <LoginLayout>
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </LoginLayout>
        );
    }

    return (
        <LoginLayout>
            <div className="login-main">
                {!isTokenValid ? (
                    <div className="alert alert-danger reset-password-invalid-token-alert" role="alert">
                        {error}
                    </div>
                ) : (
                    <form className="theme-form" onSubmit={handleSubmit}>
                        <h4>Reset Password</h4>
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        )}
                        <div className="form-group">
                            <label className="col-form-label">New Password</label>
                            <input
                                className="form-control"
                                type="password"
                                name="password"
                                required
                                value={formData.password}
                                onChange={handleChange}
                                minLength="8"
                            />
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Confirm Password</label>
                            <input
                                className="form-control"
                                type="password"
                                name="confirmPassword"
                                required
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                minLength="8"
                            />
                        </div>
                        <div className="col-12">
                            <div className="text-end">
                                <button className="btn btn-primary btn-block m-t-10" type="submit">Reset Password</button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </LoginLayout>
    );
};

export default ResetPassword;
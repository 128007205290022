import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "../views/Dashboard";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
    return (
        <>
            <Route
                path="/dashboard"
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            />
        </>
    );
};

export default AppRoutes;

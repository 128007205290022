import React from "react";
import { Route } from "react-router-dom";
import Login from "../views/user/Login";
import Register from "../views/user/Register";
import ForgotPassword from "../views/user/ForgotPassword";
import ResetPassword from "../views/user/ResetPassword";
import Logout from "../views/user/Logout";
import PublicRoute from "./PublicRoute";

const AuthRoutes = () => {
    return (
        <>
            <Route
                path="/login"
                element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                }
            />
            <Route
               path="/register"
               element={
                   <PublicRoute>
                       <Register />
                   </PublicRoute>
               }
            />
            <Route
               path="/forgot-password"
               element={
                   <PublicRoute>
                       <ForgotPassword />
                   </PublicRoute>
               }
            />
            <Route
                path="/reset-password"
                element={
                   <PublicRoute>
                       <ResetPassword />
                   </PublicRoute>
               }
            />
            <Route path="/logout" element={<Logout />} />
        </>
    );
};

export default AuthRoutes;

import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetLoginHead = () => {
        return (
            <Helmet>
                    {/* Add loading state script at the very top */}
                    <script>{`
                        // Create and show loading screen immediately
                        if (!document.getElementById('initial-loading-screen')) {
                            const loadingScreen = document.createElement('div');
                            loadingScreen.id = 'initial-loading-screen';
                            loadingScreen.style.cssText = 'position:fixed;top:0;left:0;width:100%;height:100%;background:#f8f8f8;display:flex;justify-content:center;align-items:center;z-index:9999;';
                            
                            const spinner = document.createElement('div');
                            spinner.style.cssText = 'width:50px;height:50px;border:5px solid #f3f3f3;border-top:5px solid #3498db;border-radius:50%;animation:spin 1s linear infinite;';
                            
                            const styleSheet = document.createElement('style');
                            styleSheet.textContent = '@keyframes spin{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}';
                            
                            loadingScreen.appendChild(spinner);
                            document.head.appendChild(styleSheet);
                            document.body.appendChild(loadingScreen);
                        }
                    `}</script>

                    <html lang="en"/>
                    <meta charSet="UTF-8"/>
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                    <meta name="description" content="Kopelah admin login page"/>
                    <meta name="keywords" content="retail, online, in store, start, scale, succeed, kopelah"/>
                    <meta name="author" content="Lemur Conseil"/>
                    <title>Login | Management Portal</title>

                    {/* Preload Fonts */}
                    <link rel="preconnect" href="https://fonts.googleapis.com"/>
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"/>
                    <link rel="preload" href="https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap" as="style"/>
                    <link rel="preload" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap" as="style"/>

                    {/* Load Fonts */}
                    <link href="https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap" rel="stylesheet"/>
                    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap" rel="stylesheet"/>

                    {/* Load CSS */}
                    <link rel="stylesheet" href="/assets/css/vendors/bootstrap.css"/>
                    <link rel="stylesheet" href="/assets/css/vendors/icofont.css"/>
                    <link rel="stylesheet" href="/assets/css/vendors/themify.css"/>
                    <link rel="stylesheet" href="/assets/css/vendors/flag-icon.css"/>
                    <link rel="stylesheet" href="/assets/css/vendors/feather-icon.css"/>
                    <link rel="stylesheet" href="/assets/css/style.css"/>
                    <link rel="stylesheet" href="/assets/css/responsive.css"/>
                    <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome.css"/>
            </Helmet>
        );
};

export default HelmetLoginHead;
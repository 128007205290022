// src/services/authService.js
import axios from 'axios';

const API_URL = 'https://e-retail.online/api'; // Replace with your local server URL

const authService = {
  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, { email, password });
      const user = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Login failed');
    }
  },

  signup: async (formData) => {
    try {
      const formattedData = {
        email: formData.email,
        password: formData.password,
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone: formData.phone || null,
        is_retailer: formData.isRetailer,
        business: formData.isRetailer ? {
          business_name: formData.business.business_name,
          business_address: formData.business.business_address,
          tax_id: formData.business.tax_id || null
        } : null
      };

      const response = await axios.post(`${API_URL}/auth/register`, formattedData);
      const user = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      return user;

    } catch (error) {
      throw new Error(error.response?.data?.message || 'Sign-up failed');
    }
  },

  forgotPassword: async (email) => {
    try {
      const response = await axios.post(`${API_URL}/auth/reset-password/request`, { email });
      console.log(response)
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Reset password failed');
    }
  },

  logout: () => {
    localStorage.removeItem('user');
  },

  verifyResetToken: async (token) => {
    const response = await fetch(`${API_URL}/auth/reset-password/verify?token=${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Invalid token');
    }

    return response.json();
  },

  resetPassword: async (data) => {
    const response = await fetch(`${API_URL}/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Failed to reset password');
    }

    return response.json();
  },

};

export default authService;


import React, { useState } from 'react';
import authService from '../../services/authService';
import LoginLayout from './LoginLayout';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.forgotPassword(email);
      setEmail('')
      setMessage('If the email exists, a reset link will be sent');
    } catch (error) {
      alert('Reset password failed. Please try again.');
    }
  };

  return (
      <>
        <LoginLayout>
          <div className="login-main">
            <form className="theme-form" onSubmit={handleSubmit}>
              <h4>Reset your password</h4>
              {message && (
                  <div className="alert alert-success mt-3" role="alert">
                    {message}
                  </div>
              )}
              <div className="form-group">
                <label className="col-form-label">Enter your email</label>
                <div className="row">
                  <div className="col-12">
                    <input
                        className="form-control"
                        type="email"
                        required
                        placeholder="test@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <div className="text-end">
                      <button className="btn btn-primary btn-block m-t-10" type="submit">Send</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </LoginLayout>
      </>
  );
};

export default ForgotPassword;

// routes/ProtectedRoute.js
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user) {
        // User not logged in, redirect to login page
        return <Navigate to="/login" />;
    }

    // User logged in, render the protected component
    return children;
};

export default ProtectedRoute;
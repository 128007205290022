// src/App.js
import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import './assets/scss/style.scss';
import AuthRoutes from "./routes/AuthRoutes";
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
    <Router>
      <Routes>
          {/* Redirect root to dashboard or login based on auth status */}
          <Route
              path="/"
              element={
                  localStorage.getItem('user')
                      ? <Navigate to="/dashboard" />
                      : <Navigate to="/login" />
              }
          />
          {AuthRoutes()}
          {AppRoutes()}
      </Routes>
    </Router>
  );
}

export default App;


// routes/PublicRoute.js
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
        // User is logged in, redirect to dashboard
        return <Navigate to="/dashboard" />;
    }

    // User not logged in, render the public component
    return children;
};

export default PublicRoute;
import React, { useEffect } from 'react';
import HelmetLoginHead from "../../components/HelmetLoginHead";
import Logo from "../../components/Logo";

const LoginLayout = ({ children }) => {
    useEffect(() => {
        // Function to remove loading screen
        const removeLoadingScreen = () => {
            const loadingScreen = document.getElementById('initial-loading-screen');
            if (loadingScreen) {
                loadingScreen.style.opacity = '0';
                setTimeout(() => {
                    loadingScreen.remove();
                }, 300);
            }
        };

        // Check if resources are loaded
        const checkResourcesLoaded = () => {
            if (document.readyState === 'complete') {
                removeLoadingScreen();
            } else {
                window.addEventListener('load', removeLoadingScreen);
                // Fallback
                setTimeout(removeLoadingScreen, 3000);
            }
        };

        checkResourcesLoaded();

        return () => {
            window.removeEventListener('load', removeLoadingScreen);
        };
    }, []);

    return (
        <>
            <HelmetLoginHead />
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="login-card login-dark">
                            <div className="login-content">
                                <Logo />
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginLayout;